import { VariableStorage } from "@/lib/VariableStorage";

export class PathUtils {

    static params: Record<string, string> = {
        get platform () {
            return window.innerWidth > 1200
                ? "desktop"
                : "mobile"
        },
        get extension () {
            return VariableStorage.isAvifSupported ? "avif" : "webp"
        }
    }

    static format(input: string): string {
        let result = input

        Object
            .keys(this.params)
            .forEach(it => result = result.replaceAll(`{${it}}`, this.params[it]))

        return result
    }
}
