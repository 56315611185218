
import { Options, Vue } from 'vue-class-component'
import config from '@/config.json'
import { PathUtils } from "@/lib/PathUtils";

@Options({})
export default class App extends Vue {
    pathUtils = PathUtils
    config = config
    currentPage = 0

    playing = true
    stopped = false
    muted = true
    showRotationScreen = false

    interval?: number
    progress = 0

    declare $refs: {
        pageBackground: HTMLDivElement,
        audio: HTMLAudioElement
    }

    get page () {
        return config.pages[this.currentPage]
    }

    get percentProgress () {
        return 100 - (this.progress / (this.page.duration ?? this.config.duration) * 100)
    }

    mounted () {
        const vm = this
        window.addEventListener("load", function () {
            if (vm.playing) vm.startPageInterval(vm.page)

            window.removeEventListener("load", this as any)
        })

        window.addEventListener("resize", () => {
            this.showRotationScreen = this.pathUtils.params.platform === 'mobile' &&
                window.innerWidth > window.innerHeight

            this.togglePlaying(!this.showRotationScreen)
        })

        const imgs = config.pages
            .flatMap(it => it["desktop-content"].slice(it["mobile-content"] as any) as any)
            .map(it => require(`@/assets/${this.pathUtils.format(it.src)}`))
            .filter(it => it)

        this.preload(...imgs, ...config.pages.map(it => require(`@/assets/${this.pathUtils.format(it.background)}`)))
    }

    handleClick (e: MouseEvent) {
        const isNext = e.x / this.$refs.pageBackground.offsetWidth >= 0.5

        if (isNext) this.next()
        else this.prev()
    }

    next () {
        if (this.currentPage + 1 < config.pages.length) {
            this.currentPage++
            this.progress = 0
        }
    }

    prev () {
        if (this.currentPage - 1 >= 0) {
            this.progress = 0
            this.currentPage--
        }
    }

    share () {
        navigator.share({ text: window.location.href })
    }

    toggleMusic (muted: boolean = !this.muted) {
        this.muted = muted

        if (!this.playing && !this.stopped) return

        this.$refs.audio.play()

        let vol = 1
        const fadeout = setInterval(() => {
            if (this.muted && vol > 0) vol -= 0.1
            else if (!this.muted && vol < 1) vol += 0.1;
            else {
                if (this.muted) this.$refs.audio.pause()
                else this.$refs.audio.play()

                clearInterval(fadeout);
            }

            this.$refs.audio.volume = vol
        }, 25);
    }

    togglePlaying (playing: boolean = !this.playing) {
        this.playing = playing
        this.stopped = false

        if (!this.playing) {
            if (!this.muted) this.$refs.audio.pause()
            return clearInterval(this.interval)
        }
        if ((this.page.duration ?? this.config.duration) === this.progress) {
            this.currentPage = 0
            this.progress = 0
            this.$refs.audio.currentTime = 0

            this.toggleMusic(true)
        }

        if (!this.muted) this.$refs.audio.play()
        this.startPageInterval(this.page, false)
    }

    startPageInterval (page: any, clearProgress: boolean = true) {
        if (clearProgress) this.progress = 0
        clearInterval(this.interval)

        const duration = page.duration ?? this.config.duration


        this.interval = setInterval(() => {
            if (this.progress >= duration) {
                if (this.currentPage + 1 >= this.config.pages.length) {
                    this.playing = false
                    this.stopped = true
                    return clearInterval(this.interval)
                }

                this.currentPage++
                return this.startPageInterval(this.page)
            }

            this.progress += 10
        }, 10)
    }

    runCode (code: string) {
        // eslint-disable-next-line no-eval
        return eval(code)
    }

    preload (...url: string[]) {
        url.forEach(it => new Image().src = it)
    }

    open (url: string) {
        window.open(url)
    }
}
