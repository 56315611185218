import { DirectiveBinding, ObjectDirective } from "@vue/runtime-core";
import { VariableStorage } from "@/lib/VariableStorage";

const updateSrc = (img: HTMLImageElement, binding: DirectiveBinding) => {
    let currentSrc: string;

    const setImg = (src: string) => {
        img.src = require(`@/assets/${src}`)
        currentSrc = src
    }

    img.onerror = () => {
        VariableStorage.isAvifSupported = false
        setImg(binding.value)
    }

    setImg(binding.value)
}

export class PlatformSrcDirective implements ObjectDirective<HTMLImageElement> {

    created (img: HTMLImageElement, binding: DirectiveBinding) {
        updateSrc(img, binding)
    }

    updated (img: HTMLImageElement, binding: DirectiveBinding) {
        updateSrc(img, binding)
    }
}
